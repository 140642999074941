@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
  color: #555;
  font-family: 'Inter', sans-serif;
}

a {
  color: #FF4E5D;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (max-width: 767px) {
  body {
    background-color: #fff;
  }
}

/* Hide arrows of number inputs */
@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
